import React, { Suspense } from 'react';
// Components
import Layout from './Layout/Layout';
import LoadScreen from './components/LoadScreen/LoadScreen';
// Styles
import 'react-multi-carousel/lib/styles.css';
import './App.scss';

function App() {
  return (
    <Suspense fallback={<LoadScreen />}>
      <Layout />
    </Suspense>
  );
}

export default App;
