import React from 'react';
// Style
import './Bar.scss';

/**
 * Middle bar component
 * @param {object} props - Component props
 */
const Bar = () => {
  return (
    <div className='bar-container'>
      <div className='line blue' />
      <div className='line gray' />
    </div>
  );
};

export default Bar;
