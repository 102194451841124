import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
// Styles
import './About.scss';

/**
 * About us biew component
 * @param {object} props - Component props
 */
const About = () => {
  return (
    <div className='about-container'>
      <div id='quienes-somos' className='avoid-navbar' />
      <Container className='header'>
        <h2 className='title'>Quiénes somos</h2>
        <p className='description'>
          Una empresa transportista única orientada hacia la satisfacción del
          cliente, ofreciendole garantía en la transportación de sus productos
          con rapidez, seguridad y un valor agregado de calidad en el servicio
          que distingue nuestra organización.
        </p>
      </Container>
      <div className='information'>
        <Container>
          <Row className='rows'>
            <Col className='margin-in-small' md={6}>
              <h3 className='title white'>Misión</h3>
              <p className='description'>
                Satisfacer a nuestros clientes con el mejor servicio de
                transporte en tiempo, calidad y comunicación inmediata y con
                excelencia.
              </p>
            </Col>
            <Col md={6}>
              <h3 className='title white'>Visión</h3>
              <p className='description'>
                Crear un ambiente de trabajo productivo y eficaz, integrado a la
                familia y a cada uno de los elementos que forman nuestra
                empresa.
              </p>
            </Col>
          </Row>
          {/* More information */}
          <h3 className='title white'>
            Objetivo general y compromiso con nuestros clientes
          </h3>
          <p className='description large'>
            Fortalecer e incrementar el potencial de nuestros clientes a través
            de un servicio impecable en conjunto con nuestra organización,
            otorgando posicionamiento, respuesta y bajos costes aunado a un
            servicio comprometido y vanguardista.
          </p>
        </Container>
      </div>
    </div>
  );
};

export default About;
