import React from 'react';
import { Container } from 'react-bootstrap';
// Components
import UnitsCarousel from './components/UnitsCarousel';
// Styles
import './Units.scss';

/**
 * Units view component
 * @param {object} props - Component props
 */
const Units = () => {
  return (
    <>
      <div id='unidades' className='avoid-navbar' />
      <Container className='header'>
        <h2 className='title'>Unidades</h2>
        <p className='subtitle'>Entrega seguro y express con TGP</p>
        <p className='description'>
          Todas nuestras unidades cuentan con rastreo por satelite, contamos con
          una plataforma en internet donde el cliente puede rastrear la unidad
          por medio de su usuario desde su computadora, tableta o telefono
          inteligente.
        </p>
        <p className='description'>
          Contamos con una plataforma web donde a través de un enlace usted
          puede rastrear la unidad desde su teléfono móvil, tableta o
          computadora
        </p>
      </Container>
      {/* Units carousel */}
      <UnitsCarousel />
    </>
  );
};

export default Units;
