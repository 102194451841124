import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Carousel } from 'react-bootstrap';
// Images
import BadgeImage from '../../images/svg/badge.svg';
// Styles
import './Home.scss';

/**
 * Home component
 * @param {object} props - Component props
 */
const Home = () => {
  return (
    <ScrollAnimation animateOnce animateIn='animate__fadeIn'>
      <Carousel id='inicio' className='home-screen'>
        <Carousel.Item>
          <div className='home-item'>
            <p className='subtitle uppercase'>Transportes Guzmán Padilla</p>
            <p className='large uppercase'>en todo México</p>
            <img
              className='experience-badge'
              src={BadgeImage}
              alt='Certificado - 15 años de experiencia'
            />
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className='home-item'>
            <h3 className='subtitle uppercase'>No sólo se trata de</h3>
            <h2 className='large uppercase'>Transportar</h2>
            <p className='middle-paragraph'>
              Dejamos huella en el mercado mostrando fortaleza ética y
              profesionalismo que nos ha permitido tener excelentes relaciones
              con nuestros clientes, permitiéndonos ser más sensibles a las
              necesidades de nuestros clientes.
            </p>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className='home-item'>
            <h3 className='subtitle uppercase'>Servicio</h3>
            <h2 className='large uppercase'>dedicado</h2>
          </div>
        </Carousel.Item>
      </Carousel>
    </ScrollAnimation>
  );
};

export default Home;
