import React from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Navbar, Nav, Container } from 'react-bootstrap';
// Images
import Logo from '../../images/logo.png';
// Styles
import './Navbar.scss';

/**
 * Component
 * @param {object} props - Component props
 */
const MainNavbar = () => {
  // State
  const [scrolled, setScrolled] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);

  /**
   * Move view to element
   * @param {Event} event - onclick event
   * @param {string} id - Element ID
   */
  const goToElement = (event, id) => {
    event.preventDefault();
    event.stopPropagation();

    if (id) {
      const element = document.getElementById(id);
      // Check element
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        // Change navbar `expanded` status
        setExpanded(false);
      }
    }
  };

  useScrollPosition(({ currPos }) => {
    if (currPos.y < -90) {
      setScrolled(true);
    } else if (scrolled) {
      setScrolled(false);
    }
  });

  return (
    <Navbar
      className={`custom-navbar p-0 px-3 ${
        scrolled || expanded ? 'with-background' : ''
      }`}
      expand='lg'
      expanded={expanded}
      fixed='top'
      variant='dark'
    >
      <Container>
        <Navbar.Brand
          href='#inicio'
          onClick={event => goToElement(event, 'inicio')}
        >
          <img src={Logo} alt='Logo de Transportes TGP' className='logo' />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls='responsive-navbar-nav'
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse>
          <Nav className='ml-auto'>
            <Nav.Link
              href='#inicio'
              onClick={event => goToElement(event, 'inicio')}
            >
              Inicio
              <hr className='bottom-line' />
            </Nav.Link>
            <Nav.Link
              href='#quienes-somos'
              onClick={event => goToElement(event, 'quienes-somos')}
            >
              Quiénes somos
              <hr className='bottom-line' />
            </Nav.Link>
            <Nav.Link
              href='#servicios'
              onClick={event => goToElement(event, 'servicios')}
            >
              Servicios
              <hr className='bottom-line' />
            </Nav.Link>
            <Nav.Link
              href='#unidades'
              onClick={event => goToElement(event, 'unidades')}
            >
              Unidades
              <hr className='bottom-line' />
            </Nav.Link>
            <Nav.Link
              href='#contacto'
              onClick={event => goToElement(event, 'contacto')}
            >
              Contacto
              <hr className='bottom-line' />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNavbar;
