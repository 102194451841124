import React from 'react';
// Main images
import oneTon from '../../../images/units/1ton.png';
import threeTon from '../../../images/units/3ton.jpg';
import fiveTon from '../../../images/units/5ton.jpg';
import tenTon from '../../../images/units/10ton.jpg';
import fifteenTon from '../../../images/units/15ton.jpg';
import twentyFiveTon from '../../../images/units/25ton.jpg';
// Variant images
import oneTonVariant1 from '../../../images/units/1ton-variant-1.jpg';
import oneTonVariant2 from '../../../images/units/1ton-variant-2.jpg';
import oneTonVariant3 from '../../../images/units/1ton-variant-3.jpg';
import threeTonVariant1 from '../../../images/units/3ton-variant-1.jpg';
import threeTonVariant2 from '../../../images/units/3ton-variant-2.jpg';
import threeTonVariant3 from '../../../images/units/3ton-variant-3.jpg';
import fiveTonVariant1 from '../../../images/units/5ton-variant-1.jpg';
import fiveTonVariant2 from '../../../images/units/5ton-variant-2.jpg';
import tenTonVariant1 from '../../../images/units/10ton-variant-1.jpg';
import tenTonVariant2 from '../../../images/units/10ton-variant-2.jpg';
import tenTonVariant3 from '../../../images/units/10ton-variant-3.jpg';
import fifTeenVariant1 from '../../../images/units/15ton-variant-1.jpg';
import fifTeenVariant2 from '../../../images/units/15ton-variant-2.jpg';
import fifTeenVariant3 from '../../../images/units/15ton-variant-3.jpg';
import twentyFiveVariant1 from '../../../images/units/25ton-variant-1.jpg';
import twentyFiveVariant2 from '../../../images/units/25ton-variant-2.jpg';
import twentyFiveVariant3 from '../../../images/units/25ton-variant-3.jpg';
import twentyFiveVariant4 from '../../../images/units/25ton-variant-4.jpg';
import twentyFiveVariant5 from '../../../images/units/25ton-variant-5.jpg';
import twentyFiveVariant6 from '../../../images/units/25ton-variant-6.jpg';

export default [
  {
    image: oneTon,
    subtitle: '1 tonelada',
    description: (
      <ul>
        <li>Unidades con cajas seca</li>
        <li>Unidades con plataforma/redilas</li>
      </ul>
    ),
    theme: 'dark',
    variants: [
      {
        title: null,
        image: oneTonVariant1,
      },
      {
        title: null,
        image: oneTonVariant2,
      },
      {
        title: null,
        image: oneTonVariant3,
      },
    ],
  },
  {
    image: threeTon,
    subtitle: '3 toneladas',
    description: (
      <ul>
        <li>Unidades con cajas seca</li>
        <li>Unidades con plataforma/redilas</li>
        <li>Unidades con rampa hidráulica hasta 6 metros de largo</li>
      </ul>
    ),
    theme: null,
    variants: [
      {
        title: null,
        image: threeTonVariant1,
      },
      {
        title: null,
        image: threeTonVariant2,
      },
      {
        title: null,
        image: threeTonVariant3,
      },
    ],
  },
  {
    image: fiveTon,
    subtitle: '5 toneladas',
    description: (
      <ul>
        <li>Unidades con cajas seca</li>
        <li>Unidades con plataforma/redilas</li>
        <li>Unidades con rampa hidráulica hasta 6 metros de largo</li>
      </ul>
    ),
    theme: 'dark',
    variants: [
      {
        title: null,
        image: fiveTonVariant1,
      },
      {
        title: null,
        image: fiveTonVariant2,
      },
    ],
  },
  {
    image: tenTon,
    subtitle: '10 toneladas',
    description: (
      <ul>
        <li>Tipo rabon</li>
        <li>Plataforma</li>
        <li>Caja</li>
        <li>Rampa hidráulica</li>
        <li>Desde 7 hasta 8 metros de largo</li>
      </ul>
    ),
    theme: null,
    variants: [
      {
        title: 'Rampa hidráulica',
        image: tenTonVariant1,
      },
      {
        title: null,
        image: tenTonVariant2,
      },
      {
        title: null,
        image: tenTonVariant3,
      },
    ],
  },
  {
    image: fifteenTon,
    subtitle: '15 toneladas',
    description: (
      <ul>
        <li>Tipo torton</li>
        <li>Caja</li>
        <li>Plataforma</li>
        <li>Rampa hidráulica</li>
        <li>Desde 7 hasta 8 metros de largo</li>
      </ul>
    ),
    theme: 'dark',
    variants: [
      {
        title: null,
        image: fifTeenVariant1,
      },
      {
        title: null,
        image: fifTeenVariant2,
      },
      {
        title: null,
        image: fifTeenVariant3,
      },
    ],
  },
  {
    image: twentyFiveTon,
    subtitle: '25 toneladas',
    description: (
      <ul>
        <li>Tipo trailer</li>
        <li>Caja</li>
        <li>Plataforma</li>
        <li>40 pies</li>
        <li>45 pies</li>
        <li>48 pies</li>
      </ul>
    ),
    variants: [
      {
        title: null,
        image: twentyFiveVariant1,
      },
      {
        title: null,
        image: twentyFiveVariant2,
      },
      {
        title: null,
        image: twentyFiveVariant3,
      },
      {
        title: null,
        image: twentyFiveVariant4,
      },
      {
        title: null,
        image: twentyFiveVariant5,
      },
      {
        title: null,
        image: twentyFiveVariant6,
      },
    ],
  },
];
