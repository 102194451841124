import React from 'react';
import { Spinner } from 'react-bootstrap';

/**
 * Load screen with React Suspence
 * @param {object} props - Component props
 */
const LoadScreen = () => {
  return (
    <div className='d-flex justify-content-center align-items-center bg-dark h-100 w-100'>
      <Spinner />
    </div>
  );
};

export default LoadScreen;
