import React from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Button, Toast } from 'react-bootstrap';
// Icons
import locationIcon from '../../images/svg/location.svg';
import scheduleIcon from '../../images/svg/schedule.svg';
import emailIcon from '../../images/svg/email.svg';
import facebookIcon from '../../images/svg/facebook.svg';
import phoneIcon from '../../images/svg/phone.svg';
import whatsappIcon from '../../images/svg/whatsapp.svg';
// Styles
import './Contact.scss';

/**
 * Contact view component
 * @param {object} props - Component props
 */
const Contact = () => {
  // State
  const [formData, setFormData] = React.useState({
    name: '',
    business: '',
    subject: '',
    message: '',
  });
  const [validated, setValidated] = React.useState(false);
  const [sendingMessage, setSendingMessage] = React.useState(false);
  const [messageSent, setMessageSent] = React.useState('not-sent');

  /**
   * Sends the form data
   * @param {Event} event - onSubmit event
   */
  const sendFormData = event => {
    // Prevent default events
    event.preventDefault();
    event.stopPropagation();
    // Get form
    const form = event.currentTarget;
    // Check form verification
    if (form.checkValidity()) {
      // Set sending message flag
      setSendingMessage(true);
      // Sent email using script route
      axios
        .post('/scripts/sendEmail.php', formData, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        })
        .then(() => {
          setSendingMessage(false);
          // Reset form
          setFormData({
            name: '',
            business: '',
            subject: '',
            message: '',
          });
          // Show toast
          setMessageSent('sent');
        })
        .catch(error => {
          console.error(error);
          // Set sending message flag
          setSendingMessage(false);
          // Show toast
          setMessageSent('error');
        });
    } else {
      console.warn('Faltan campos');
    }

    setValidated(true);
  };

  return (
    <>
      {/* Contact toast messages */}
      <Toast
        autohide={true}
        className='custom-toast'
        delay={5000}
        onClose={() => setMessageSent('not-sent')}
        show={messageSent === 'sent'}
      >
        <Toast.Header>
          <strong className='mr-auto'>Contacto</strong>
          <small>Ahora</small>
        </Toast.Header>
        <Toast.Body>
          El mensaje fue enviado correctamente. Pronto nos contactaremos
          contigo.
        </Toast.Body>
      </Toast>
      <Toast
        autohide={true}
        className='custom-toast'
        delay={5000}
        onClose={() => setMessageSent('not-sent')}
        show={messageSent === 'error'}
      >
        <Toast.Header>
          <strong className='mr-auto'>Contacto</strong>
          <small>Ahora</small>
        </Toast.Header>
        <Toast.Body>
          Hubo un error al enviar el mensaje. Por favor intente más tarde.
        </Toast.Body>
      </Toast>

      <Container className='contact-container'>
        <div id='contacto' className='avoid-navbar' />
        <div className='header'>
          <h2 className='title'>Contactanos</h2>
          <p className='description'>
            No solo se trata de transportar. Dejamos huella en el mercado
            mostrando fortaleza ética y profesionalismo que nos ha permitido
            tener excelentes clientes, permitiendonos ser más sensibles a sus
            necesidades especificas. Contactanos queremos conocer tus
            necesidades.
          </p>
          <p>
            <b>Tenemos horario de atención 24/7</b>
          </p>
        </div>
        {/* Contact cards */}
        <Row className='contact-cards'>
          {/* Phone numbers */}
          <Col className='card-holder left' md={6}>
            <Row className='inner-card px-2 py-3'>
              <Col className='icon-container' xs={3}>
                <img alt='Teléfonos:' className='icon' src={phoneIcon} />
              </Col>
              <Col className='information' xs={9}>
                <b>Teléfonos:</b>
                <p>Móvil: (33) 1422 6349</p>
                <p>Oficina: (33) 1029 7139</p>
              </Col>
            </Row>
          </Col>
          {/* Location */}
          <Col className='card-holder right' md={6}>
            <Row className='inner-card px-2 py-3'>
              <Col className='icon-container' xs={3}>
                <img alt='Ubicación' className='icon' src={locationIcon} />
              </Col>
              <Col className='information' xs={9}>
                <b>Ubicación:</b>
                <p>
                  Calle Orquídea 501, colinia Salvador Portillo López,
                  Tlaquepaque, Jalisco
                </p>
              </Col>
            </Row>
          </Col>
          {/* Schedule */}
          <Col className='card-holder left' md={6}>
            <Row className='inner-card px-2 py-3'>
              <Col className='icon-container' xs={3}>
                <img alt='Ubicación' className='icon' src={scheduleIcon} />
              </Col>
              <Col className='information' xs={9}>
                <b>Horarios:</b>
                <p>
                  Contamos con atención las 24 horas los 7 dias de la semana.
                </p>
              </Col>
            </Row>
          </Col>
          {/* Emails */}
          <Col className='card-holder right' md={6}>
            <Row className='inner-card px-2 py-3'>
              <Col className='icon-container' xs={3}>
                <img alt='Ubicación' className='icon' src={emailIcon} />
              </Col>
              <Col className='information' xs={9}>
                <b>E-mail:</b>
                <p>
                  <a href='mailto:info@transportestgp.com'>
                    info@transportestgp.com
                  </a>
                </p>
                <p>
                  <a href='mailto:tgp@live.com.mx'>tgp@live.com.mx</a>
                </p>
              </Col>
            </Row>
          </Col>
          <Col className='card-holder left' md={6}>
            <Row className='inner-card px-2 py-3'>
              <Col className='icon-container' xs={3}>
                <img alt='Ubicación' className='icon' src={facebookIcon} />
              </Col>
              <Col className='information' xs={9}>
                <b>Facebook:</b>
                <p>
                  <a
                    href='https://www.facebook.com/TGPTrucKing'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Transportes Guzman Padilla
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
          <Col className='card-holder right' md={6}>
            <Row className='inner-card px-2 py-3'>
              <Col className='icon-container' xs={3}>
                <img alt='Whatsapp' className='icon' src={whatsappIcon} />
              </Col>
              <Col className='information' xs={9}>
                <b>WhatsApp:</b>
                <p>
                  <a
                    href='https://wa.me/+523314226349'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    Principal - (33) 1422 6349
                  </a>
                </p>
                <p>
                  <a
                    href='https://wa.me/+523313854963'
                    rel='noopener noreferrer'
                    target='_blank'
                  >
                    (33) 1385 4963
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Contact form */}
        <Form noValidate validated={validated} onSubmit={sendFormData}>
          <h3 className='text-center my-3'>
            Envianos un correo y te asesoramos
          </h3>
          <Row className='px-md-2 px-lg-5'>
            <Col md={6}>
              <Form.Control
                disabled={sendingMessage}
                required
                className='custom-input'
                placeholder='Nombre completo'
                size='lg'
                onChange={event =>
                  setFormData({
                    ...formData,
                    name: event.currentTarget.value,
                  })
                }
                value={formData.name}
              />
              <Form.Control
                disabled={sendingMessage}
                required
                className='custom-input'
                placeholder='Empresa'
                size='lg'
                onChange={event =>
                  setFormData({
                    ...formData,
                    business: event.currentTarget.value,
                  })
                }
                value={formData.business}
              />
              <Form.Control
                disabled={sendingMessage}
                required
                className='custom-input'
                placeholder='Asunto'
                size='lg'
                onChange={event =>
                  setFormData({
                    ...formData,
                    subject: event.currentTarget.value,
                  })
                }
                value={formData.subject}
              />
            </Col>
            <Col md={6}>
              <Form.Control
                as='textarea'
                className='custom-input h-100'
                disabled={sendingMessage}
                placeholder='Mensaje'
                required
                rows={5}
                size='lg'
                onChange={event =>
                  setFormData({
                    ...formData,
                    message: event.currentTarget.value,
                  })
                }
                value={formData.message}
              />
            </Col>
          </Row>
          {/* Submit button */}
          <div className='send-button-container d-flex justify-content-center'>
            <Button
              disabled={sendingMessage}
              className='send-button rounded-pill'
              type='submit'
            >
              Enviar
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
};

export default Contact;
