import React from 'react';
// Components
import Navbar from './Navbar/Navbar';
import Bar from '../components/Bar/Bar';
import FloatingButton from '../components/FloatingButton/FloattingButton';
// Views
import Home from '../views/Home/Home';
import About from '../views/About/About';
import Services from '../views/Services/Services';
import Units from '../views/Units/Units';
import Contact from '../views/Contact/Contact';
import Footer from '../views/Footer/Footer';

/**
 * Main layout component
 * @param {object} props - Component props
 */
const Layout = () => {
  return (
    <>
      {/* Navbar */}
      <Navbar />
      {/* Content */}
      <Home />
      <About />
      <Services />
      <Units />
      <Bar />
      <Contact />
      <Footer />
      {/* Whatsapp floating button */}
      <FloatingButton />
    </>
  );
};

export default Layout;
