import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// Images
import logo from '../../images/logo-without-character.png';
// Styles
import './Footer.scss';

/**
 * Footer view component
 * @param {object} props - Component props
 */
const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <div className='footer'>
        <Container>
          <Row>
            <Col className='mb-2 mb-lg-0' md={6} lg={4}>
              <img alt='Logo Transportes TGP' className='logo' src={logo} />
              <p className='description'>
                Una empresa transportista única orientada hacia la satisfacción
                del cliente, ofreciendole garantía en la transportación de sus
                productos con rapidez, seguridad y un valor agregado de calidad
                en el servicio que distingue nuestra organización.
              </p>

              <hr className='d-md-none' />
            </Col>

            <Col className='mb-2 mb-lg-0' md={6} lg={4}>
              <h4 className='title'>Siguenos en nuestras redes sociales</h4>
              <p>
                Facebook:{' '}
                <a
                  href='https://www.facebook.com/TGPTrucKing'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  Transportes Guzmán Padilla
                </a>
              </p>
            </Col>
            <Col md={12} lg={4}>
              <h4 className='title'>Servicios</h4>
              <p>Transporte</p>
              <p>Logística</p>

              <hr />

              <h4 className='title'>Contacto</h4>
              <p>Teléfonos:</p>
              <p>
                <a href='tel:(33) 1029 1739'>(33) 1029 1739</a>
              </p>
              <p>Correos:</p>
              <p>
                <a href='mailto:info@transportestgp.com'>
                  info@transportestgp.com
                </a>
              </p>
              <p>
                <a href='mailto:tgp@live.com.mx'>tgp@live.com.mx</a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Copyright footer */}
      <div className='copyright-container'>
        <Container>
          <Row>
            <Col className='mb-2 mb-md-0 text-center text-lg-left' md={4}>
              Derechos reservados {year} | <p className='white'>©TGP</p>
            </Col>
            <Col className='mb-2 mb-md-0 text-center text-lg-left' md={4}>
              Politicas de privacidad | Soporte
            </Col>
            <Col className='mb-2 mb-md-0 text-center text-lg-left' md={4}>
              Diseño por:{' '}
              <p className='white'>
                <a
                  href='https://amoletech.com/'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  AMOLE | Tecnología y diseño
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
