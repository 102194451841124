import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
// Icons
import transportationIcon from '../../images/svg/transportation.svg';
import logisticsIcon from '../../images/svg/logistics.svg';
// Images
import logo from '../../images/logo-without-character.png';
// Styles
import './Services.scss';

/**
 * Services view component
 * @param {object} props - Component props
 */
const Services = () => {
  return (
    <div className='services-container'>
      <div id='servicios' className='avoid-navbar' />
      <Container className='header'>
        <h2 className='title'>Servicios que realizamos</h2>
        <p className='description'>
          Una compañía dedicada al transporte de carga general dentro de México;
          envíos urgentes y express, fletes especiales, apoyo en carga y
          descarga, logística de envío y embalaje, experiencia y profesionalismo
          que destacan nuestra profesionalidad y organización.
        </p>
      </Container>
      {/* Services information */}
      <Container className='mb-5'>
        <Row>
          <Col
            className='d-flex justify-content-center align-items-center flex-column text-center mb-4 mb-md-0'
            md={6}
          >
            <img
              alt='Trasporte'
              className='service-icon mb-4'
              src={transportationIcon}
            />
            <h4 className='font-weight-bold'>Transporte</h4>
            <p className='paragraph'>
              Flotilla impecable y gran variedad de capacidades de carga, con
              unidades de reciente modelo.
            </p>
          </Col>
          <Col
            className='d-flex justify-content-center align-items-center flex-column text-center'
            md={6}
          >
            <img
              alt='Logística'
              className='service-icon mb-4'
              src={logisticsIcon}
            />
            <h4 className='font-weight-bold'>Logística</h4>
            <p className='paragraph'>
              Hacemos logística de transporte y embalaje, experimentada y
              profesional para que tu paquete llegue como se envió.
            </p>
          </Col>
        </Row>
        {/* Services cards */}
        <Row className='services-cards justify-content-center'>
          <Col className='custom-card white' lg={4} md={6}>
            <div className='inner-card background logistics-service m-3 shadow'>
              <div className='information'>
                <h5 className='title'>Logística</h5>
                <p className='description mb-0'>
                  Su mercancía a tiempo y de forma segura.
                </p>
              </div>
            </div>
          </Col>
          <Col className='custom-card' lg={4} md={6}>
            <div className='inner-card background personal-service m-3 shadow'>
              <div className='information'>
                <h5 className='title'>Personal</h5>
                <p className='description mb-0'>
                  Capacitado, uniformado, amable y con actitud de servicio.
                </p>
              </div>
            </div>
          </Col>
          <Col className='custom-card' lg={4} md={6}>
            <div className='inner-card background attention-service m-3 shadow'>
              <div className='information'>
                <h5 className='title'>Atención personalizada</h5>
                <p className='description mb-0'>24/7</p>
              </div>
            </div>
          </Col>
          <Col className='custom-card' lg={4} md={6}>
            <div className='inner-card background licenses-service m-3 shadow'>
              <div className='information'>
                <h5 className='title'>Rastreo satelital</h5>
                <p className='description mb-0'>
                  Rastreo de unidades en tiempo real.
                </p>
              </div>
            </div>
          </Col>
          <Col className='custom-card white' lg={4} md={6}>
            <div className='inner-card background insurance-service m-3 shadow'>
              <div className='information'>
                <h5 className='title'>Seguro de mercancía</h5>
                <p className='description mb-0'>
                  Contamos con 2 polizas de aseguramiento para ti.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Staff information */}
      <div className='staff-container'>
        <div className='logo-container d-flex justify-content-center'>
          <img alt='Logo Transportes TGP' className='logo' src={logo} />
        </div>
        <Container className='header'>
          <h2 className='title white'>Personal</h2>
          <p className='description'>
            Hacemos un gran compromiso al asignar el mejor personal: uniformado,
            capacitado y en comunicación total las 24 horas.
          </p>
          <p className='description'>
            Cabe mencionar que en este punto todos nuestros operadores cuentan
            con licencia de clase federal y asegurados ante el IMSS.
          </p>
        </Container>
      </div>
    </div>
  );
};

export default Services;
