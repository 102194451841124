import React from 'react';
import Carousel from 'react-multi-carousel';
import { Button, Modal } from 'react-bootstrap';
// Data
import data from './data';

/**
 * Component
 * @param {object} props - Component props
 */
const UnitsCarousel = props => {
  // State
  const [modalInfo, setModalInfo] = React.useState({
    show: false,
    title: '',
    variants: [],
  });

  const responsiveBehavior = {
    desktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1200, min: 766 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 766, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      {/* Variants modal */}
      <Modal
        className='custom-modal'
        onHide={() => setModalInfo({ ...modalInfo, show: false })}
        show={modalInfo.show}
        size='lg'
      >
        <Modal.Header className='custom-modal-header' closeButton={true}>
          Variantes de {modalInfo.title}
        </Modal.Header>
        <Modal.Body>
          {modalInfo.variants && modalInfo.variants.length
            ? modalInfo.variants.map((variant, index) => {
                const { title, image } = variant;

                return (
                  <div className='variant' key={`variant-${index}`}>
                    <p className='title'>{title}</p>
                    <img className='w-100' src={image} alt={title} />
                  </div>
                );
              })
            : 'No tiene variaciones'}
        </Modal.Body>
      </Modal>
      {/* Units carousel */}
      <Carousel
        containerClass='units-carousel container'
        removeArrowOnDeviceType={['tablet', 'mobile']}
        responsive={responsiveBehavior}
        {...props}
      >
        {data.map(unit => {
          return (
            <div
              key={unit.subtitle}
              className='d-flex justify-content-center h-100'
            >
              <div className='d-flex flex-column units-card shadow'>
                <img
                  alt={`Unidad ${unit.title}`}
                  className='header-img'
                  src={unit.image}
                />
                <div
                  className={`d-flex flex-column information h-100 ${unit.theme}`}
                >
                  <p className='title'>{unit.title}</p>
                  <p className='subtitle'>{unit.subtitle}</p>
                  {unit.description ? (
                    <p className='description'>{unit.description}</p>
                  ) : null}
                  <div className='d-flex justify-content-end mt-auto'>
                    <Button
                      className='open-modal'
                      onClick={() =>
                        setModalInfo({
                          show: true,
                          title: unit.subtitle,
                          variants: unit.variants,
                        })
                      }
                    >
                      Ver variantes
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
    </>
  );
};

export default UnitsCarousel;
