import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
// Images
import button from '../../images/whatsapp-icon.png';
// Styles
import './FloatingButton.scss';

/**
 * WhatsApp floating button
 * @param {object} params - Component parameters
 */
const FloatingButton = () => {
  return (
    <a
      href='https://wa.me/+523314226349'
      rel='noopener noreferrer'
      target='_blank'
    >
      <OverlayTrigger
        placement='top-start'
        overlay={
          <Tooltip>Envíanos un mensaje por WhatsApp para asesorarte</Tooltip>
        }
      >
        <img
          alt='Envíanos un mensaje por WhatsApp para asesorarte'
          className='floating-button'
          src={button}
        />
      </OverlayTrigger>
    </a>
  );
};

export default FloatingButton;
